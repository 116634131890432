import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';

import { NgxGistModule } from 'ngx-gist/dist/ngx-gist.module';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';

import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AppBrandingComponent } from './components/app-branding/app-branding.component';

import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { InterfaceComponent } from './components/interface/interface.component';
import { ContentComponent } from './components/content/content.component';
import { ChartsComponent } from './components/charts/charts.component';

import { EmailsComponent } from './components/emails/emails.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TrainingComponent } from './components/training/training.component';

import { BuildingBlocksComponent } from './components/building-blocks/building-blocks.component';
import { HeaderComponent } from './components/header/header.component';
import { TopnavComponent } from './components/topnav/topnav.component';

import { MisComponent } from './components/mis/mis.component';
import { MyadminComponent } from './components/mis/myadmin/myadmin.component';
import { MycolorsComponent } from './components/mis/mycolors/mycolors.component';
import { MydeliveriesComponent } from './components/mis/mydeliveries/mydeliveries.component';
import { MyreportsComponent } from './components/mis/myreports/myreports.component';
import { MyordersComponent } from './components/mis/myorders/myorders.component';
import { MysalesComponent } from './components/mis/mysales/mysales.component';
import { MyspecsComponent } from './components/mis/myspecs/myspecs.component';
import { MytimeComponent } from './components/mis/mytime/mytime.component';
import { MyscheduleComponent } from './components/mis/myschedule/myschedule.component';
import { PqmComponent } from './components/mis/pqm/pqm.component';
import { SubnavComponent } from './components/mis/subnav/subnav.component';
import { MyreportshubComponent } from './components/mis/myreportshub/myreportshub.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppBrandingComponent,

    PageLayoutComponent,
    InterfaceComponent,
    ContentComponent,
    ChartsComponent,
    MisComponent,
    EmailsComponent,
    PageNotFoundComponent,
    TrainingComponent,

    BuildingBlocksComponent,
    HeaderComponent,
    TopnavComponent,
    MyadminComponent,
    MycolorsComponent,
    MydeliveriesComponent,
    MyreportsComponent,
    MyordersComponent,
    MysalesComponent,
    MyspecsComponent,
    MytimeComponent,
    MyscheduleComponent,
    PqmComponent,
    SubnavComponent,
    MyreportshubComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxGistModule,
    MarkdownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
