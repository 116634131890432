import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AppBrandingComponent } from './components/app-branding/app-branding.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { InterfaceComponent } from './components/interface/interface.component';
import { ContentComponent } from './components/content/content.component';
import { ChartsComponent } from './components/charts/charts.component';

import { EmailsComponent } from './components/emails/emails.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TrainingComponent } from './components/training/training.component';
import { BuildingBlocksComponent } from './components/building-blocks/building-blocks.component';
import { MisRoutes } from './components/mis/mis.routes';

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'app-branding', component: AppBrandingComponent
  },
  {
    path: 'page-layout', component: PageLayoutComponent
  },
  {
    path: 'interface', component: InterfaceComponent
  },
  {
    path: 'content', component: ContentComponent
  },
  {
    path: 'charts', component: ChartsComponent
  },
  ...MisRoutes,
  {
    path: 'emails', component: EmailsComponent
  },
  {
    path: 'training', component: TrainingComponent
  },
  {
    path: 'building-blocks', component: BuildingBlocksComponent
  },

  {
    path: '**', component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
exports: [RouterModule]
})
export class AppRoutingModule { }
