import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mytime',
  templateUrl: './mytime.component.html',
  styleUrls: ['./mytime.component.less']
})
export class MytimeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
