import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { url } from 'inspector';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  title = '';

  pathSettings = [
    { path: 'app-branding', title: 'Branding' },
    { path: 'page-layout', title: 'Layouts' },
    { path: 'interface', title: 'Boilerplate' },
    { path: 'building-blocks', title: 'Components' },
    { path: 'charts', title: 'Charts' },
    { path: 'mis', title: 'MIS Apps' }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.url.subscribe(url => {
      const urlPath = url[0].path;
      this.title = this.getTitle(urlPath);
    });
  }

  getTitle(urlPath: string): string {
    const pathSetting = this.pathSettings.find(titles => titles.path === urlPath);
    if (pathSetting) {
      return pathSetting.title;
    } else {
      return urlPath;
    }
  }
}
