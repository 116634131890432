import { Route } from '@angular/router';
import { MisComponent } from './mis.component';
import { MyadminComponent } from './myadmin/myadmin.component';
import { MycolorsComponent } from './mycolors/mycolors.component';
import { MydeliveriesComponent } from './mydeliveries/mydeliveries.component';
import { MyordersComponent } from './myorders/myorders.component';
import { MyreportsComponent } from './myreports/myreports.component';
import { MyreportshubComponent } from './myreportshub/myreportshub.component';
import { MysalesComponent } from './mysales/mysales.component';
import { MyspecsComponent } from './myspecs/myspecs.component';
import { MytimeComponent } from './mytime/mytime.component';
import { MyscheduleComponent } from './myschedule/myschedule.component';
import { PqmComponent } from './pqm/pqm.component';


export const MisRoutes: Route[] = [

  {
      path: 'mis',
      component:MisComponent
  },
  {
      path: 'mis/myadmin',
      component: MyadminComponent
  },
  {
    path: 'mis/mycolors',
    component: MycolorsComponent
  },
  {
    path: 'mis/mydeliveries',
    component: MydeliveriesComponent
  },
  {
    path: 'mis/myorders',
    component: MyordersComponent
  },
  {
    path: 'mis/myreports',
    component: MyreportsComponent
  },
  {
    path: 'mis/myreportshub',
    component: MyreportshubComponent
  },
  {
    path: 'mis/mysales',
    component: MysalesComponent
  },
  {
    path: 'mis/myspecs',
    component: MyspecsComponent
  },
  {
    path: 'mis/myschedule',
    component: MyscheduleComponent
  },
  {
    path: 'mis/mytime',
    component: MytimeComponent
  },
  {
    path: 'mis/pqm',
    component: PqmComponent
  }

];
