import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-building-blocks',
  templateUrl: './building-blocks.component.html',
  styleUrls: ['./building-blocks.component.less']
})
export class BuildingBlocksComponent implements OnInit {

  constructor() { }

  htmlSummary = `

  \`\`\`html
<div class="row no-gutters summary">
  <dl>
    <dt># of Customers</dt>
    <dd>{{CustomerCount}}</dd>
  </dl>
  <dl>
    <dt>Default Status</dt>
    <dd>{{isDefault}}</dd>
  </dl>
  <dl>
    <dt>PT Reporting Category</dt>
    <dd>{{reportingCategory}}</dd>
  </dl>
</div>
  \`\`\``;

  myTestTagsHtml = `

  \`\`\`css
  body,p {
    font-family: 'roboto', 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 100;
    line-height: 1.2em;
    color: #333;

  }
  \`\`\``;

  htmlCard = `
    \`\`\`html
  <article class="card">
      <div class="card-header">
        <h3>Title</h3>
      </div>
      <div class="card-body">
        Content
      </div>
  </article>

  \`\`\``;



  cssCard = `
    \`\`\`css
    .card {
      border:1px solid #999;
    }


  \`\`\``;




  ngOnInit() {
  }

}
