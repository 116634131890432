import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myreportshub',
  templateUrl: './myreportshub.component.html',
  styleUrls: ['./myreportshub.component.less']
})
export class MyreportshubComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
