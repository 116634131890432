import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pqm',
  templateUrl: './pqm.component.html',
  styleUrls: ['./pqm.component.less']
})
export class PqmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
