import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.less']
})
export class EmailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
