import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myschedule',
  templateUrl: './myschedule.component.html',
  styleUrls: ['./myschedule.component.less']
})
export class MyscheduleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
