import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myreports',
  templateUrl: './myreports.component.html',
  styleUrls: ['./myreports.component.less']
})
export class MyreportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
